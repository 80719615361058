import React from "react"
import { motion } from "framer-motion"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImg from "gatsby-background-image"
import logoArenales from "../images/logoArenalesBig.png"

const HeroDesarrollos = props => {
  return (
    <motion.div
      initial={{ scale: 1.2 }}
      animate={{ scale: 1 }}
      transition={{ duration: 3 }}
    >
      <BackgroundImg
        className="text-white text-center grid grid-cols-1 h-screen content-center items-center bg-fixed"
        fluid={props.fondo}
      >
        <motion.img
          initial={{ opacity: 0, y: -100, scale: 1.3 }}
          animate={{ opacity: 1, y: 0, scale: 1 }}
          transition={{ duration: 3 }}
          src={props.logo}
          alt="logo"
          className="m-auto p-12"
          // width="300px"
        />
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 3 }}
        >
          <h1 className="text-xl leading-none font-semibold tracking-wider lg:text-3xl px-4 pt-4 lg:pt-12">
            {props.tagline}
          </h1>
          {/* <h3 className="text-lg text-gray-800 px-4"></h3>
                    <p className="pt-24 lg:pt-4 text-md lg:text-xl font-semibold text-white text-montserrat font-thin px-12">
                        Comercialización y Asesoría en Productos de Inversión
                    </p> */}
        </motion.div>
      </BackgroundImg>
    </motion.div>
  )
}

export default HeroDesarrollos
