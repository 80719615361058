import React, { useRef, useState, useEffect } from "react"
import "../global.css"
import { graphql, useStaticQuery } from "gatsby"
import HeadMetadata from "../components/HeadMetadata"
import HeroDesarrollos from '../desarrollos/HeroDesarrollos'
import Layout from '../layout/Layout'
import logoLibera from '../images/logoLibera.png'
import { ItemIconoNosotros } from '../home/itemIconoNosotros'
import logo from '../images/logo.png'
import BackgroundImg from "gatsby-background-image"
import Img from "gatsby-image"
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import edoardo from "../images/edoardo.jpg"
import roger from "../images/roger.jpg"
import ruescas from "../images/ruescas.jpg"
import yoyi from "../images/rodrigo.jpg"
import chris from "../images/gibran.jpg"
import francia from "../images/francia.jpg"
import CallToAction from '../layout/CallToActionC'

export default () => {

  const img = useStaticQuery(graphql`
    {
      fondoHero: file(relativePath: { eq: "tapirnosotros2.jpg" }) {
        sharp: childImageSharp { fluid(maxWidth: 1800, quality:90) { ...GatsbyImageSharpFluid } } }
      fondo: file(relativePath: { eq: "fondoNosotros.jpg" }) { sharp: childImageSharp {
          fluid(maxWidth: 1920) { ...GatsbyImageSharpFluid } } } 
      fondoblur: file(relativePath: { eq: "fondoNosotrosBlur.jpg" }) { sharp: childImageSharp {
            fluid(maxWidth: 1920) { ...GatsbyImageSharpFluid } } } 
    img2: file(relativePath: { eq: "liberaNosotros2.jpg" }) { 
        sharp: childImageSharp { fluid(maxWidth: 1800,quality:90) { ...GatsbyImageSharpFluid } } }
    img2Res: file(relativePath: { eq: "liberaNosotrosVer.jpg" }) { 
        sharp: childImageSharp { fluid(maxWidth: 1800,quality:90) { ...GatsbyImageSharpFluid } } }

    img3: file(relativePath: { eq: "tapirnosotros.jpg" }) { 
        sharp: childImageSharp { fluid(maxWidth: 1800,quality:90) { ...GatsbyImageSharpFluid } } }
    img3Res: file(relativePath: { eq: "tapirnosotrosver.jpg" }) { 
        sharp: childImageSharp { fluid(maxWidth: 1800,quality:90) { ...GatsbyImageSharpFluid } } }
    
    img4: file(relativePath: { eq: "ubicacionnosotros.jpg" }) { 
        sharp: childImageSharp { fluid(maxWidth: 1800,quality:90) { ...GatsbyImageSharpFluid } } }
    img4Res: file(relativePath: { eq: "ubicacionnosotrosVer.jpg" }) { 
        sharp: childImageSharp { fluid(maxWidth: 1800,quality:90) { ...GatsbyImageSharpFluid } } }

    img5: file(relativePath: { eq: "liberaNosotros3.png" }) { 
        sharp: childImageSharp { fluid(maxWidth: 1800,quality:90) { ...GatsbyImageSharpFluid } } }
    img5Res: file(relativePath: { eq: "liberaNosotrosVer3.png" }) { 
        sharp: childImageSharp { fluid(maxWidth: 1800,quality:90) { ...GatsbyImageSharpFluid } } }
    img6: file(relativePath: { eq: "us.jpg" }) { 
        sharp: childImageSharp { fluid(maxWidth: 1800,quality:90) { ...GatsbyImageSharpFluid } } }
    img6Res: file(relativePath: { eq: "us.jpg" }) { 
        sharp: childImageSharp { fluid(maxWidth: 1800,quality:90) { ...GatsbyImageSharpFluid } } }
    img7: file(relativePath: { eq: "descarga.jpg" }) { 
        sharp: childImageSharp { fluid(maxWidth: 1800,quality:90) { ...GatsbyImageSharpFluid } } }
    img7Res: file(relativePath: { eq: "descarga.jpg" }) { 
        sharp: childImageSharp { fluid(maxWidth: 1800,quality:90) { ...GatsbyImageSharpFluid } } }
img8: file(relativePath: { eq: "feyac.png" }) { 
        sharp: childImageSharp { fluid(maxWidth: 1800,quality:90) { ...GatsbyImageSharpFluid } } }
    img8Res: file(relativePath: { eq: "feyac.png" }) { 
        sharp: childImageSharp { fluid(maxWidth: 1800,quality:90) { ...GatsbyImageSharpFluid } } }

    
     
    }
  `)

  return (
    <Layout className="overflow-hidden">
      <HeadMetadata title="Nosotros - Grupo Libera" />


      <HeroDesarrollos />
      <a href="https://grupolibera.mx/pdf/declaracionResponsabilidad.pdf" target="_blank">
      <section>
            <Img fluid={img.fondoHero.sharp.fluid}  className="hidden lg:block" />  
  
        </section>
        </a>
      <div
            className="py-24 grid grid-cols-1 items-center text-gray-700">
            <div className="container m-auto grid justify-center px-4">
                {/* <div className="px-6 sm:px-32 md:px-24 lg:px-4 xl:px-24 lg:ml-12 pt-12 my-12 m-auto container max-w-3xl z-20"> */}
                <h1 className="text-3xl sm:text-4xl md:text-4xl text-libera-500 mb-4
                    font-bold text-center text-quan leading-10 md:px-8 lg:px-0"
                    data-sal="slide-up"
                    data-sal-delay="0"
                    data-sal-easing="ease"
                    data-sal-duration="1200"
                >
                    ¿Quiénes somos?
                </h1>
                {/* <h2 className="mt-8 md:text-xl md:px-12 lg:text-3xl font-semibold lg:px-0 px-4 sm:px-0 text-base text-center"
                    data-sal="slide-up"
                    data-sal-delay="100"
                    data-sal-easing="ease"
                    data-sal-duration="1300">
                    Asegura tu futuro invirtiendo en las mejores zonas de Yucatán.
                </h2> */}
                <p className="text-center text-lg mt-8">
                Somos un equipo de profesionales que se encarga de la comercialización y desarrollo de bienes inmuebles.<br />
                Nuestro compromiso se encuentra en la mejora continua de los entornos que habitamos a través de la educación y alianzas que hacemos con personas expertas en áreas medioambientales, sociales, de salud, turismo y más. Cuidando, además, la atención que le brindamos a nuestra clientela.
                </p>

                {/* </div> */}
            </div>
        </div>
        <section>
            <Img fluid={img.img5.sharp.fluid}  className="hidden lg:block" />  
            <Img fluid={img.img5Res.sharp.fluid}  className="lg:hidden" />  
        </section>
        <a href="https://unglobalcompact.org/what-is-gc/participants/152829-CASA-BASTI-N-S-A-DE-C-V-LIBERA-" target="_blank">
        <section>
            <Img fluid={img.img6.sharp.fluid}  className="hidden lg:block" />  
            <Img fluid={img.img6Res.sharp.fluid}  className="lg:hidden" />  
        </section>
        </a>
        <a href="https://grupolibera.mx/pdf/Pacto-Mundial.pdf" target="_blank">
        <section>
            <Img fluid={img.img7.sharp.fluid}  className="hidden lg:block" />  
            <Img fluid={img.img7Res.sharp.fluid}  className="lg:hidden" />  
        </section>
        </a>
        
        <div
        
            className="py-8 grid grid-cols-1 lg:grid-cols-2 items-center py-12 lg:py-22 text-gray-700 container m-auto">
<div class="px-6 my-12 m-auto container max-w-3xl text-white">
<div style={{width:'100%',display:'flex',justifyContent:'center',paddingBottom:'10px',paddingTop:'100px'}}>
          <div style={{width:'100%'}}><iframe width="100%" id="frame" src="https://www.youtube-nocookie.com/embed/tqwb3UrIuKk?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div></div>
</div>
                <div className="container m-auto grid justify-center px-4">
                <Accordion allowZeroExpanded preExpanded={['a']}>
                    <AccordionItem uuid="a">
                        <AccordionItemHeading>
                            <AccordionItemButton>
                              Nuestra Misión
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                            Nuestra misión es proporcionarle al cliente una experiencia de 
inversión satisfactoria con productos diseñados para 
incrementar su plusvalía; a través de un equipo especializado de 
agentes capacitados en el ramo inmobiliario. Además de crear y 
favorecer iniciativas que ayuden y promuevan el cuidado con el 
medio ambiente, conciencia social, laboral y profesional de 
nuestros espacios y comunidades. Esto de la mano de expertos, 
asociaciones civiles y autoridades correspondientes.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Visión
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                            Ser la empresa que se destaca por su diversidad, colaboración y 
contribución a la sociedad y al medio ambiente gracias a 
nuestra experiencia, promoción y difusión en temas 
económicos y educativos de la región, manteniendo el prestigio 
en cuanto temas de inversiones inmobiliarias se trata.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Valores
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div>
                                
                                <ul>
                                    <li>Creatividad</li>
                                    <li>Transparencia</li>
                                    <li>Innovación</li>
                                    <li>Responsabilidad Social</li>
                                    <li>Trabajo Colaborativo</li>
                                </ul>
                                <p style={{color:"white"}}>Ser la empresa que se destaca por su diversidad, colaboración y contribución a la sociedad y al medio ambiente gracias a nuestra experiencia, promoción y difusión en temas económicos y educativos de la región, manteniendo el prestigio en cuanto temas de inversiones inmobiliarias se trata.</p>
                            </div>
                            
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
                </div>
               
                
            </div>
            <section>
            <Img fluid={img.img8.sharp.fluid}  className="hidden lg:block" />  
            <Img fluid={img.img8Res.sharp.fluid}  className="lg:hidden" />  
        </section>
        <BackgroundImg fluid={img.fondo.sharp.fluid} className="bg-fixed px-4 py-32">
                <div className="container m-auto rounded-md overflow-hidden">
                    <BackgroundImg fluid={img.fondoblur.sharp.fluid} className=" rounded-md bg-fixed rounded-lg">
                        <div className="bg-black bg-opacity-50 grid grid-cols-1 md:grid-cols-2 py-12 items-center">
                            <div className="grid grid-cols-1 justify-center items-center">

                                <h1
                                    className="text-2xl font-semibold lg:text-3xl px-4 xl:mx-12 py-8 px-8 text-white text-center
                                lg:bg-opacity-0 rounded-lg text-quan"
                                    data-sal="slide-up"
                                    data-sal-duration="600"
                                    data-sal-easing="ease" >
                                    4 Pilares Principales
                                </h1>
                            </div>
                            <div className="md:border-l md:border-white">
                                <div
                                    data-sal="slide-up"
                                    data-sal-duration="800"
                                    data-sal-easing="ease"
                                    className="text-xs lg:text-sm grid grid-cols-1 md:grid-cols-1 ref2
                    md:px-8 xl:mx-24 justify-start"
                                >
                                    <ItemIconoNosotros texto="Marketing" icono="persona" />
                                    <ItemIconoNosotros texto="Responsabilidad Social y Ambiental" icono="balanza" />
                                    <ItemIconoNosotros texto="Comercialización y Operación" icono="libro" />
                                    <ItemIconoNosotros texto="Administración" icono="hoja" />
                                </div>
                            </div>
                        </div>
                    </BackgroundImg>
                    <BackgroundImg fluid={img.fondoblur.sharp.fluid} className=" rounded-md bg-fixed rounded-lg">
                        <div className="bg-black bg-opacity-75 grid items-center">
                            <img src={logo} alt="logo" width="40px" className="m-auto my-5" />
                        </div>
                    </BackgroundImg>
                </div>
            </BackgroundImg>


            <section>
                <Img fluid={img.img2.sharp.fluid}  className="hidden lg:block" />  
                <Img fluid={img.img2Res.sharp.fluid}  className="lg:hidden" />  
            </section>
            <div
            className=" grid grid-cols-1 items-center text-gray-700" style={{paddingTop: "6rem" }}>
            <div className="container m-auto grid justify-center px-4">
                {/* <div className="px-6 sm:px-32 md:px-24 lg:px-4 xl:px-24 lg:ml-12 pt-12 my-12 m-auto container max-w-3xl z-20"> */}
                <h1 className="text-3xl sm:text-4xl md:text-4xl text-libera-500 mb-4
                    font-bold text-center text-quan leading-10 md:px-8 lg:px-0"
                    
                >
                    Dirección Libera
                </h1>       
            </div>
        </div>
            <div
            className=" grid grid-cols-1 items-center text-gray-700">
                
                <div className="m-auto max-w-screen-lg font-100 text-montserrat text-sm text-white grid grid-cols-1 sm:grid-cols-3 text-center py-12 sm:py-24 items-center justify-center">
                        <img
                className="py-2"
                src={edoardo}
                width="100%"
                />
                <img
                className="py-2"
                src={roger}
                width="100%"
                />
                <img
                className="py-2"
                src={ruescas}
                width="100%"
                />
                <img
                className="py-2"
                src={yoyi}
                width="100%"
                />
                <img
                className="py-2"
                src={chris}
                width="100%"
                />
                <img
                className="py-2"
                src={francia}
                width="100%"
                />
                </div>
                

            </div>
            <section>
                <Img fluid={img.img4.sharp.fluid}  className="hidden lg:block" />  
                <Img fluid={img.img4Res.sharp.fluid}  className="lg:hidden" />  
            </section>
            <div
            className=" grid grid-cols-1 items-center text-gray-700" style={{paddingTop: "6rem", backgroundColor:"#000" }}>
            <div className="container m-auto grid justify-center px-4">
                {/* <div className="px-6 sm:px-32 md:px-24 lg:px-4 xl:px-24 lg:ml-12 pt-12 my-12 m-auto container max-w-3xl z-20"> */}
                <h1 className="text-3xl sm:text-4xl md:text-4xl text-libera-500 mb-4
                    font-bold text-center text-quan leading-10 md:px-8 lg:px-0"
                    
                >
                    ¿Tienes alguna duda?
                </h1>
                       
            </div>
        </div>
            <CallToAction
        phone="tel:9993462300"
        whatsapp="https://wa.me/+529993462300"
        email="mailto:info@grupolibera.mx"
      />

            

    </Layout >
  )
}

