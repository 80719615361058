import React from "react"
import iconoPersona from "../images/marketing.png"
import iconoBalanza from "../images/responsabilidadSocial.png"
import iconoLibro from "../images/comercializacionOrganizacion.png"
import iconoHoja from "../images/administracion.png"
import iconoCheck from "../images/iconoCheck.png"

let icono

const QueIcono = props => {
  if (props === "persona") {
    icono = iconoPersona
  }
  if (props === "balanza") {
    icono = iconoBalanza
  }
  if (props === "libro") {
    icono = iconoLibro
  }
  if (props === "hoja") {
    icono = iconoHoja
  }
  if (props === "check") {
    icono = iconoCheck
  }
  return icono
}

export const ItemIconoNosotros = props => {
  icono = QueIcono(props.icono)
  return (
    <div
      data-sal="slide-up"
      data-sal-duration="1000"
      data-sal-easing="ease"
      className="py-2 sm:py-4 md-py-4 px-8 text-lg px-16 container m-auto "
    >
      <div className="container flex items-center ">
        <img
          className="py-2"
          src={icono}
          width="32px"
          alt="icono servicio"
        />
        <span className="text-white text-left text-sm ml-6">{props.texto}</span>
      </div>
    </div>
  )
}
